<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <tabelas-operacionais-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getExecucoesProcedurePrincipal"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import refreshDataMixins from '@/mixins/refreshDataMixins';
import ExecucoesProcedurePrincipalGeoPerdasService from '@/services/ExecucoesProcedurePrincipalGeoPerdasService';
import TabelasOperacionaisDataTable from '@/components/perdas-tecnicas/preencher-tabelas-operacionais/TabelasOperacionaisDataTable';

export default {
  mixins: [refreshDataMixins],
  components: {
    TabelasOperacionaisDataTable
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getExecucoesProcedurePrincipal);
  },
  methods: {
    getExecucoesProcedurePrincipal() {
      this.loading = true;
      ExecucoesProcedurePrincipalGeoPerdasService.getExecucoes()
        .then((responseData) => (this.items = responseData))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
