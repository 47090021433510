import APIService from './APIService';

export default {
  getViewsAtivas() {
    return APIService.apiCall().get('/diagnosticos-pre-etapa-17/get-views-ativas');
  },
  getDadosViewDiagnostico(bdgdVersaoId, viewDiagnostico) {
    return APIService.apiCall().get(`/diagnosticos-pre-etapa-17/get-dados-view-diagnostico/${bdgdVersaoId}/${viewDiagnostico}`);
  },
  atualizaDadosViewDiagnostico(bdgdVersaoId, viewDiagnostico) {
    return APIService.apiCall().get(`/diagnosticos-pre-etapa-17/atualiza-dados-view-diagnostico/${bdgdVersaoId}/${viewDiagnostico}`);
  },
  baixarResultadoDadosViewDiagnostico(bdgdVersaoId, viewDiagnostico, config = {}) {
    return APIService.apiCall().get(
      `/diagnosticos-pre-etapa-17/baixar-resultado-dados-view-diagnostico/${bdgdVersaoId}/${viewDiagnostico}`,
      { responseType: 'blob', ...config }
    );
  }
};
