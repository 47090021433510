<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-table-sync"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Etapa 17 NVAL</div>
      </template>

      <v-tabs
        centered
        v-model="tabsLevel1"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          href="#tab-geoperdas-aneel"
          class="primary--text"
        >
          GeoPerdas ANEEL
        </v-tab>
        <v-tab
          href="#tab-pre-etapa-17"
          class="primary--text"
        >
          Diagnósticos pré etapa 17
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabsLevel1">
        <v-tab-item value="tab-geoperdas-aneel">
          <v-btn
            v-if="accessReleased('PREENCHER_TABELAS_OPERACIONAIS_ADICIONAR')"
            id="btn-nova-execucao"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('nova-execucao')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Nova Execução
          </v-btn>
          <v-text-field
            id="execucoes-procedure-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />
          <general-progress-bars
            class="mt-11"
            :items="downloadProgressData"
            prefixText="Baixando zip ..."
          />
          <v-divider class="mt-10" />
          <v-alert
            dense
            outlined
            type="info"
            class="mb-5 py-1 px-1"
          >
            <strong>
              Após classificar corretamente os campos COD_BNC das entidades
              <!-- prettier-ignore -->
              <router-link :to="getLinkVisualizarEntidade('eqre')">EQRE</router-link>
              ,
              <!-- prettier-ignore -->
              <router-link :to="getLinkVisualizarEntidade('eqtrat')">EQTRAT</router-link>
              e
              <!-- prettier-ignore -->
              <router-link :to="getLinkVisualizarEntidade('eqtrmt')">EQTRMT</router-link>
              e
              <!-- prettier-ignore -->
              <router-link :to="'/perdas-tecnicas/carregar-bdgd/historico'">carregar os dados da BDGD</router-link>
              em um servidor GeoPerdas, execute a procedure "Principal" para
              preencher as tabelas operacionais.
            </strong>
          </v-alert>
          <v-alert
            dense
            outlined
            type="info"
            class="mb-5 py-1 px-1"
          >
            <strong>
              Essas tabelas serão necessárias para o uso do programa GeoPerdas
              da ANEEL.
            </strong>
          </v-alert>
          <v-data-table
            class="execucoes-procedure-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Servidor',
                value: 'servidor'
              },
              {
                text: 'Mês dos dados',
                value: 'data_registro'
              },
              {
                text: 'Nº de linhas',
                value: 'numero_linhas'
              },
              {
                text: 'Erros críticos',
                value: 'erros_criticos'
              },
              {
                text: 'Não críticos',
                value: 'erros_nao_criticos'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="items"
            :search.sync="search"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            no-data-text="Nenhum agendamento encontrado"
          >
            <template v-slot:[`item.servidor`]="{ item }">
              <div :title="`Host: ${item.host} Port: ${item.port}`">
                {{ item.servidor }} <br />{{ item.database }}
              </div>
            </template>
            <template v-slot:[`item.numero_linhas`]="{ item }">
              {{ item.numero_linhas | parseNumberToIntegerBR }}
            </template>
            <template v-slot:[`item.erros_criticos`]="{ item }">
              <span :class="{ 'erros-criticos': item.erros_criticos > 0 }">
                {{ item.erros_criticos | parseNumberToIntegerBR }}
              </span>
            </template>
            <template v-slot:[`item.erros_nao_criticos`]="{ item }">
              <span
                :class="{ 'erros-nao-criticos': item.erros_nao_criticos > 0 }"
              >
                {{ item.erros_nao_criticos | parseNumberToIntegerBR }}
              </span>
            </template>
            <template v-slot:[`item.data_registro`]="{ item }">
              {{ item.data_registro | formatToMonth }} v{{ item.versao }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                :class="!podeBaixarZip(item.status) ? 'cursor-block' : ''"
                id="action-download-bdgd"
                class="px-1"
                min-width="0"
                fab
                icon
                x-small
                @click="baixarZip(item)"
                :loading="
                  downloadProgressData[item.id] !== undefined ? true : false
                "
                :disabled="!podeBaixarZip(item.status)"
              >
                <v-icon small> mdi-download </v-icon>
              </v-btn>
              <v-btn
                v-if="
                  item.status === 'EXECUTANDO' &&
                  accessReleased('PREENCHER_TABELAS_OPERACIONAIS_ANDAMENTO')
                "
                id="action-andamento"
                class="px-1"
                min-width="0"
                fab
                icon
                x-small
                @click="
                  $router.push(
                    `/perdas-tecnicas/preencher-tabelas-operacionais/andamento/${item.id}`
                  )
                "
              >
                <v-icon small> mdi-file-eye-outline </v-icon>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="action-more-options"
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="canDeleteItem(item)"
                    @click="openDialogDelete(item)"
                  >
                    <v-list-item-icon class="mx-0 my-0 py-4">
                      <v-icon small>mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Deletar </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openDialogCreationLog(item)">
                    <v-list-item-title>
                      <v-icon small> mdi-table-search </v-icon>
                      Log
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('rechargeTable')"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar as etapas</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-pre-etapa-17">
          <diagnosticos-pre-etapa-17
            :bdgdVersao="userSelectedCompanyBdgdVersion"
          />
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">
        Deletar Execução #{{ dialogDeleteData.id }}
      </template>
      <template slot="body">
        Tem certeza que deseja deletar o agendamento de execução da procedure
        "Principal"
        <strong> #{{ dialogDeleteData.id }} </strong>?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ExecucoesProcedurePrincipalGeoPerdasService from '@/services/ExecucoesProcedurePrincipalGeoPerdasService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';
import DialogCreationLog from '@/components/general/DialogCreationLog';
import DialogDelete from '@/components/general/DialogDelete';
import GeneralStatus from '@/components/general/GeneralStatus.vue';
import GeneralProgressBars from '@/components/general/GeneralProgressBars.vue';
import DiagnosticosPreEtapa17 from './DiagnosticosPreEtapa17.vue';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog,
    DialogDelete,
    GeneralStatus,
    GeneralProgressBars,
    DiagnosticosPreEtapa17
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    tabsLevel1: null,
    powerupService: ExecucoesProcedurePrincipalGeoPerdasService,
    powerupEntityName: 'Execuções da Procedure Principal GeoPerdas',
    dialogCreationLog: false,
    dialogCreationLogData: {
      username: null,
      created: null
    },
    dialogDelete: false,
    dialogDeleteData: {
      id: null
    },
    search: '',
    loadingLogsTecnicos: {}
  }),
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.usuario,
        created: item.created
      };
    },
    canDeleteItem(item) {
      return item.status === 'AGUARDANDO';
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id
      };
    },
    deletarItem(id) {
      ExecucoesProcedurePrincipalGeoPerdasService.deletar(id)
        .then(() => {
          this.$toast.success('Agendamento removido com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar o agendamento.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDelete = false;
    },
    getLinkVisualizarEntidade(entidade) {
      if (!this.userSelectedCompanyBdgdVersion) return '';
      return `/geracao-bdgd/entidade-bdgd/visualizar/${entidade}/${this.userSelectedCompanyBdgdVersion.id}`;
    },
    podeBaixarZip(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    baixarZip(execucao) {
      const { id, data_registro: dataRegistro, versao } = execucao;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `execucao-procedure-principal-mes-${dataRegistro}-versao-${versao}-${timestamp}.zip`;
      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });
      ExecucoesProcedurePrincipalGeoPerdasService.baixarZip(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com os logs da execução da procedure.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  }
};
</script>

<style>
.erros-criticos {
  color: #e91e63;
}
.erros-nao-criticos {
  color: #f9a825;
}
</style>
