<template>
  <div class="mt-10">
    <v-progress-circular
      v-if="loadingViewsAtivas"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-alert
        text
        color="teal"
        class="mt-2"
      >
        <v-row
          align="center"
          no-gutters
        >
          <v-col class="grow">
            <p>
              As abas abaixo exibem resultados de views escritas para encontrar
              potenciais erros na BDGD selecionada a fim de reportar problemas
              que podem afetar a execução da Etapa 17 do NVAL.
            </p>
            <p>
              O ideal é que estes potenciais erros sejam corrigidos antes de
              executar os testes da Etapa 17 na aba "GeoPerdas ANEEL".
            </p>
          </v-col>
        </v-row>
      </v-alert>
      <v-tabs
        fixed-tabs
        v-model="currentItem"
      >
        <v-tab
          v-for="item in items"
          :key="item.id"
          :href="'#tab-' + item.id"
          class="primary--text"
        >
          {{ item.nome }}
        </v-tab>
        <v-menu
          v-if="more.length"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              class="primary--text align-self-center mr-4"
              v-bind="attrs"
              v-on="on"
            >
              mais ({{ more.length }})
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list class="grey lighten-3">
            <v-list-item
              v-for="item in more"
              :key="item.id"
              @click="addItem(item)"
            >
              {{ item.nome }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
      <v-tabs-items v-model="currentItem">
        <v-tab-item
          v-for="item in items.concat(more)"
          :key="item.id"
          :value="'tab-' + item.id"
        >
          <v-row>
            <v-col cols="12">
              <v-alert
                text
                color="info"
                class="mt-2"
                v-if="viewSelecionada.descricao"
              >
                <v-row
                  align="center"
                  no-gutters
                >
                  <v-col class="grow">
                    <strong>{{ viewSelecionada.nome }}</strong> ::
                    {{ viewSelecionada.descricao }}
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <diagnosticos-pre-etapa-17-data-table
                :bdgdVersao="bdgdVersao"
                :viewDiagnostico="viewSelecionada"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import DiagnosticosPreEtapa17Service from '@/services/DiagnosticosPreEtapa17Service';
import DiagnosticosPreEtapa17DataTable from './DiagnosticosPreEtapa17DataTable.vue';
export default {
  name: 'DiagnosticosPreEtapa17',
  components: {
    DiagnosticosPreEtapa17DataTable
  },
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    currentItem: 'tab-1',
    items: [],
    more: [],
    loadingViewsAtivas: false,
    viewsAtivas: [],
    numAbasVisiveis: 5
  }),
  mounted() {
    this.getViewsDiagnosticosPreEtapa17();
  },
  computed: {
    viewSelecionadaId() {
      return Number(this.currentItem.replace('tab-', ''));
    },
    viewSelecionada() {
      const index = this.viewsAtivas.findIndex(
        (view) => view.id === this.viewSelecionadaId
      );
      return this.viewsAtivas[index];
    }
  },
  methods: {
    addItem(item) {
      const removed = this.items.splice(0, 1);
      const itemIndex = this.more.findIndex((el) => el.nome === item.nome);
      this.items.push(...this.more.splice(itemIndex, 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = 'tab-' + item.id;
      });
    },
    getViewsDiagnosticosPreEtapa17() {
      this.loadingViewsAtivas = true;
      DiagnosticosPreEtapa17Service.getViewsAtivas()
        .then(({ data }) => {
          this.viewsAtivas = data;
          this.items = this.viewsAtivas.slice(0, this.numAbasVisiveis);
          this.more = this.viewsAtivas.slice(
            this.numAbasVisiveis,
            this.viewsAtivas.length
          );
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao buscar as views de diagnósticos ativas.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error(error);
        })
        .finally(() => (this.loadingViewsAtivas = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getViewsDiagnosticosPreEtapa17();
    }
  }
};
</script>
