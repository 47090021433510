<template>
  <div>
    <v-progress-circular
      v-if="loadingAtualizacaoView"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-row>
        <v-col cols="8">
          <v-btn
            small
            title="Atualizar o resultado"
            outlined
            class="mb-n10 mr-2"
            @click="atualizarResultado"
            :loading="loadingAtualizacaoResultado"
          >
            <v-icon
              small
              color="primary"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
          <v-btn
            v-if="resultado.length > 1"
            small
            title="Baixar o resultado em Excel"
            outlined
            class="mb-n10 mr-2"
            @click="baixarResultado"
            :loading="loadingBaixarResultado"
          >
            <v-icon
              small
              color="primary"
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-if="resultado.length > 1"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mb-5 break-search"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 350px"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="resultado"
        :search.sync="search"
        :mobile-breakpoint="800"
        :hide-default-footer="!resultado.length"
        no-data-text="Nenhuma linha de dados retornada pela consulta"
      >
        <template v-slot:[`item.ultima_atualizacao_em`]="{ item }">
          {{ item.ultima_atualizacao_em | parseToDateTimeBR }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DiagnosticosPreEtapa17Service from '@/services/DiagnosticosPreEtapa17Service';
export default {
  name: 'DiagnosticosPreEtapa17DataTable',
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    },
    viewDiagnostico: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.getDadosViewDiagnostico();
  },
  data: () => ({
    loadingAtualizacaoView: false,
    loadingAtualizacaoResultado: false,
    loadingBaixarResultado: false,
    search: null,
    resultado: []
  }),
  computed: {
    headers() {
      if (!this.resultado.length) {
        return [];
      }
      return Object.keys(this.resultado[0]).map((header) => ({
        text: header.toUpperCase(),
        value: header
      }));
    }
  },
  methods: {
    getDadosViewDiagnostico() {
      this.loadingAtualizacaoView = true;
      DiagnosticosPreEtapa17Service.getDadosViewDiagnostico(
        this.bdgdVersao.id,
        this.viewDiagnostico.view
      )
        .then(({ data }) => {
          this.resultado = data;
        })
        .catch((error) => {
          this.$toast.error(
            `Erro ao buscar os dados do diagnóstico para "${this.viewDiagnostico.nome}".`,
            '',
            {
              position: 'topRight'
            }
          );
          console.error(error);
        })
        .finally(() => (this.loadingAtualizacaoView = false));
    },
    atualizarResultado() {
      this.loadingAtualizacaoResultado = true;
      DiagnosticosPreEtapa17Service.atualizaDadosViewDiagnostico(
        this.bdgdVersao.id,
        this.viewDiagnostico.view
      )
        .then(() => this.getDadosViewDiagnostico())
        .catch((error) => {
          this.$toast.error(
            `Erro ao atualizar os dados do diagnóstico para "${this.viewDiagnostico.nome}".`,
            '',
            {
              position: 'topRight'
            }
          );
          console.error(error);
        })
        .finally(() => (this.loadingAtualizacaoResultado = false));
    },
    baixarResultado() {
      this.loadingBaixarResultado = true;
      DiagnosticosPreEtapa17Service.baixarResultadoDadosViewDiagnostico(
        this.bdgdVersao.id,
        this.viewDiagnostico.view,
        {
          timeout: 60 * 60 * 1000
        }
      )
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const nomeViewDiagnostico = this.viewDiagnostico.view.replaceAll(
            '_',
            '-'
          );
          const arquivo = `resultado-diagnostico-${nomeViewDiagnostico}-${timestamp}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo resultado.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingBaixarResultado = false));
    }
  }
};
</script>
