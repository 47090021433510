import APIService from './APIService';

export default {
  getExecucao(id) {
    return APIService.apiCall().get(`/geo-perdas/execucoes-procedure-principal/get-by-id/${id}`);
  },
  getExecucoes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/geo-perdas/execucoes-procedure-principal/get-all')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },
  getAuxLogs(servidorId, bdgdVersaoId, databasePerdas) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/geo-perdas/execucoes-procedure-principal/get-aux-logs/${servidorId}/${bdgdVersaoId}/${databasePerdas}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  save(postData) {
    return APIService.apiCall().post(
      '/geo-perdas/execucoes-procedure-principal/save',
      JSON.stringify(postData)
    );
  },
  deletar(id) {
    return APIService.apiCall().delete(
      `/geo-perdas/execucoes-procedure-principal/${id}`
    );
  },
  baixarZip(id, config = {}) {
    return APIService.apiCall().get(
      `/geo-perdas/execucoes-procedure-principal/download-arquivo-zip-logs/${id}`,
      { responseType: 'blob', ...config }
    );
  }
};
